import "./studiolocations.scss";

import { IDCLocation } from "../common/Icons";
import React from "react";
import HoverVideoPlayer from 'react-hover-video-player';
import { StudioVideos } from "./StudioVideos";
import bengalure_icon from "../../img/bengalure_icon.png";
import campus2 from "../../img/campus2.png";
import campus4 from "../../img/campus4.png";
import campus5 from "../../img/campus5.png";
import chaiVideo from "../../img/chai.webm";
import hyd_icon from "../../img/hyd_icon.png";
import idclocations from "../../img/idc_locations.png";
import mscampusVideo from "../../img/ms-hyd.webm";
import noida_icon from "../../img/noida_icon.png";
import pin from "../../img/pin.png";
import sofaVideo from "../../img/sofa.webm";


export const StudioLocations: React.FC = () => {
  return (
    <>
          <div className="studioLocations" role="region" aria-label="Microsoft IDC Office in India">
        <div className="locInfo">
          <div>
            <img className="pin_icon" src={pin} alt="pin Icon" />
            <div className="locInfoTxt">
              Come look at where we live, work and thrive
            </div>
            <div className="locInfoSubTxt">
              Creative folks need creative spaces. Our offices aren't just
              offices. They're places that you can call home.
            </div>
          </div>
          <div className="idcLocations">
            <img src={idclocations} alt="Microsoft IDC office location at Noida,Hyderabad,Bengaluru in India" />
            {/* <div className="indiaMap">{IDCLocation}</div>
            <div className="noida_loc locationsImgPair">
              <img src={noida_icon} alt="noida icon" />
              <span>Noida</span>
            </div>
            <div className="hyd_loc locationsImgPair">
              <img src={hyd_icon} alt="noida icon" />
              <span>Hyderabad</span>
            </div>
            <div className="bengalure_loc locationsImgPair">
              <img src={bengalure_icon} alt="noida icon" />
              <span>Bengalure</span>
            </div> */}
          </div>
        </div>
        <div className="locCards">
          <div className="row1">
                      
                      { /* for web accessibility */}
                      <HoverVideoPlayer videoSrc={mscampusVideo}
                          focused={true}
                          videoClassName="player"
                          videoId="hover-video1"
                          onHoverStart={() => {
                              document.getElementById("hover-video1")!.setAttribute('aria-label', 'Graphic Interchange Format contains Picture-in-Picture')
                          }}
                      />
                      <img src={campus2} alt="campus2" />

                      { /* for web accessibility */}
                      <HoverVideoPlayer videoSrc={chaiVideo}
                          focused={true}
                          videoClassName="player"
                          videoId="hover-video2"
                          onHoverStart={() => {
                              document.getElementById("hover-video2")!.setAttribute('aria-label', 'Graphic Interchange Format contains Picture-in-Picture')
                          }}
                      />
          </div>
          <div className="row2">
            <div>
              <img src={campus4} alt="campus4" />
              <img src={campus5} alt="campus5" />
            </div>
            { /* for web accessibility */}
            <HoverVideoPlayer videoSrc={sofaVideo}
                focused={true}
                videoClassName="player largeImg"
                videoId="hover-video3"
                onHoverStart={() => {
                    document.getElementById("hover-video3")!.setAttribute('aria-label', 'Graphic Interchange Format contains Picture-in-Picture')
                }}
            />
          </div>
        </div>
      </div>
      <StudioVideos />
    </>
  );
};
