import "./footer.scss";

import { MSLogo, StudioLogo } from "../common/Icons";

import React from "react";

export const Footer: React.FC = () => {
  return (
      <footer className="footer">
      <div className="footerContainer">
        <div className="footer-left">
          <div className="logo">
            <div className="mslogo">{MSLogo}</div>
            <div className="separator"></div>
            <div className="stlogo">{StudioLogo}</div>
          </div>
          <div className="footerTxt">
            Here to delight everyone, everyday, everytime!
          </div>
        </div>
        <div className="footer-right">
          <div>
            <h4>Discover</h4>
              <nav className="footer-links" aria-label="Discover Footer">
              <a href="https://news.microsoft.com/stories/" target="_blank">Story Labs</a>
              <a href="https://www.microsoft.com/en-in/about/" target="_blank">About Microsoft</a>
              <a href="https://www.microsoft.com/en-us/diversity/default.aspx" target="_blank">Diversity &amp; Inclusion</a>
            </nav>
          </div>
          <div>
            <h4>Connect</h4>
             <nav className="footer-links" aria-label="Connect Footer">
              <a href="https://careers.microsoft.com/professionals/us/en/locations#India" target="_blank">Careers</a>
              <a href="https://www.microsoft.com/en-in/msidc" target="_blank">Microsoft IDC</a>
            </nav>
          </div>
        </div>
      </div>
          <ul className="privacy-link">
              <li><a href="https://support.microsoft.com/contactus">Contact Microsoft</a></li>
              <li><a href="https://privacy.microsoft.com/en-us/privacystatement">Privacy</a></li>
              <li><a href="https://www.microsoft.com/en-us/legal/terms-of-use">Terms of use</a></li>
              <li><a href="https://www.microsoft.com/en-us/legal/intellectualproperty/trademarks">Trademarks</a></li>
              <li><a href="https://choice.microsoft.com/">About our ads</a></li>
              <li>&#169; Microsoft 2022</li>
          </ul>  
      </footer>
  );
};
