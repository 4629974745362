import * as React from "react";

export const layer3Icon1 = (
    <svg role="img" aria-labelledby="layer3Icon1"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
   <title id="layer3Icon1">Microsoft Outlook app icon</title>
    <path d="M44.8389 11.446H58.1607V23.2877H44.8389V11.446Z" fill="#50D9FF" />
    <path
      d="M44.8389 23.2878H58.1607V35.8696H44.8389V23.2878Z"
      fill="#28A8EA"
    />
    <path
      d="M44.8389 35.8696H58.9008V48.4514H44.8389V35.8696Z"
      fill="#0078D4"
    />
    <path
      d="M59.3774 28.5516L39.2713 16.3219L39.0098 16.1526C38.6237 15.9555 38.1973 15.8502 37.7638 15.845C37.3352 15.8503 36.9138 15.9557 36.5331 16.1526L36.2562 16.3219L16.1194 28.5362C15.7286 28.7831 15.4907 29.2124 15.4887 29.6746C15.4834 30.1548 15.7359 30.6009 16.1501 30.8438L36.287 43.0581L36.5639 43.212C36.9419 43.4158 37.365 43.5216 37.7945 43.5196C38.229 43.5222 38.6572 43.4165 39.0406 43.212L39.3021 43.0581L59.3774 30.8438C59.767 30.5903 59.9993 30.1547 59.9927 29.69C59.9969 29.23 59.7645 28.8001 59.3774 28.5516Z"
      fill="#123B6D"
    />
    <path d="M16.9272 11.23H30.8492V23.5367H16.9272V11.23Z" fill="#0078D4" />
    <path d="M30.8438 11.23H44.6119V23.5367H30.8438V11.23Z" fill="#28A8EA" />
    <path
      d="M30.8438 23.5366H44.6119V35.8433H30.8438V23.5366Z"
      fill="#0078D4"
    />
    <path
      d="M30.8438 35.8435H44.6119V49.6885H30.8438V35.8435Z"
      fill="#0364B8"
    />
    <path
      d="M17.1655 35.3821H30.8413V46.7812H17.1655V35.3821Z"
      fill="#14447D"
    />
    <path
      d="M17.1655 22.8599H30.8413V35.3973H17.1655V22.8599Z"
      fill="#0364B8"
    />
    <path
      d="M58.4574 11.23V4.93822C58.4574 4.30751 58.3207 3.50816 57.8358 2.97134C57.3509 2.43453 56.6724 2.11272 55.9499 2.07692L19.5375 2C18.811 2.03192 18.1271 2.35149 17.6365 2.88826C17.1459 3.42502 16.8889 4.13489 16.9223 4.86131V11.23H58.4574Z"
      fill="#0358A7"
    />
    <path
      opacity="0.5"
      d="M36.9183 45.8425L13.8433 31.9974V29.6899L35.5551 40.5458V40.5458C36.4141 40.9753 37.4244 40.9791 38.2866 40.5559L59.9964 29.9007V29.9007L59.9934 31.2283L36.9183 45.8425Z"
      fill="#0A2767"
    />
    <path
      d="M59.4459 30.8283L38.5706 43.0735L38.2937 43.2273C37.8937 43.4315 37.4507 43.537 37.0015 43.535C36.5573 43.5375 36.1191 43.4319 35.7247 43.2273L35.4478 43.0735L14.6034 30.8591C14.1975 30.6248 13.9503 30.1892 13.9573 29.7207V54.411C13.9449 55.1889 14.2432 55.9395 14.786 56.4968C15.3288 57.054 16.0714 57.3719 16.8493 57.38H57.1999C57.9751 57.3679 58.7137 57.0482 59.2533 56.4914C59.7928 55.9346 60.012 55.0785 59.9997 54.3033V29.6899C60.0098 30.1553 59.8458 30.5901 59.4459 30.8283Z"
      fill="#1490DF"
    />
    <path
      d="M57.1321 57.38C57.7785 57.3791 58.4083 57.1745 58.9319 56.7954L36.9029 43.4888L36.6106 43.3965L14.7509 30.7668L13.8433 29.6899V54.3033C13.8431 55.9847 15.2696 57.3548 16.9507 57.38H57.1321Z"
      fill="#28A8EA"
    />
    <path
      opacity="0.6"
      d="M16.9199 14.3066V12.7683H29.7343C31.1531 12.7683 32.3033 13.9185 32.3033 15.3373V43.535C32.3033 45.2342 30.9258 46.6117 29.2266 46.6117H13.8433V45.0734L16.9199 14.3066Z"
      fill="#0A2767"
    />
    <path
      d="M2.56902 14.3066H28.1977C29.6166 14.3066 30.7667 15.4568 30.7667 16.8757V42.5044C30.7667 43.9232 29.6166 45.0734 28.1977 45.0734H2.56902C1.15019 45.0734 0 43.9232 0 42.5044V16.8757C0 15.4568 1.15019 14.3066 2.56902 14.3066Z"
      fill="#107AD5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9476 21.9521C9.66941 22.709 8.6504 23.8353 8.0248 25.1826C7.3255 26.6631 6.973 28.2835 6.99411 29.9207C6.96732 31.4778 7.31555 33.0184 8.00942 34.4126C8.64779 35.6968 9.64771 36.7662 10.8861 37.4893C12.2217 38.2605 13.7439 38.649 15.2857 38.6123C16.8536 38.6458 18.401 38.2523 19.7623 37.4739C21.0317 36.72 22.0489 35.6065 22.6851 34.2742C23.4055 32.8054 23.7692 31.1873 23.7466 29.5515C23.7754 27.9495 23.4109 26.3649 22.6851 24.9365C22.057 23.6731 21.0801 22.6161 19.87 21.8906C18.5637 21.1316 17.0731 20.7483 15.5626 20.783C13.9461 20.7412 12.3493 21.1457 10.9476 21.9521ZM12.7509 34.905C12.0178 34.3554 11.4486 33.616 11.1049 32.7667L11.0588 32.7974C10.3027 30.816 10.3027 28.6255 11.0588 26.6441C11.4085 25.781 11.9951 25.0344 12.7509 24.4904C13.5226 23.957 14.4437 23.6823 15.3815 23.7059C16.359 23.6694 17.3197 23.9679 18.1043 24.552C18.8158 25.1305 19.3491 25.8984 19.6427 26.7672C19.9766 27.7317 20.1431 28.7463 20.1349 29.7669C20.1449 30.7803 19.9676 31.7869 19.6119 32.7359C19.3178 33.583 18.7832 34.326 18.0736 34.8742C17.2735 35.4344 16.3112 35.7155 15.3353 35.6741C14.4145 35.6956 13.5102 35.4265 12.7509 34.905Z"
      fill="white"
    />
  </svg>
);

export const layer3Icon2 = (
    <svg role="img" aria-labelledby="layer3Icon2"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer3Icon2">Microsoft Teams app icon</title>
    <path
      d="M40.3125 38.6569V24.7397C40.3125 24.3943 40.4497 24.0631 40.6939 23.8189C40.9381 23.5747 41.2693 23.4375 41.6147 23.4375H56.5734C57.2334 23.4375 57.8664 23.6996 58.3331 24.1662C58.7999 24.6328 59.0622 25.2656 59.0625 25.9256V38.6569C59.0622 41.085 58.0976 43.4137 56.3806 45.1306C54.6636 46.8476 52.335 47.8123 49.9069 47.8125H49.4681C47.0399 47.8125 44.7111 46.8479 42.9941 45.1309C41.2771 43.4139 40.3125 41.0851 40.3125 38.6569Z"
      fill="#5059C9"
    />
    <path
      d="M50.1563 20.625C53.5217 20.625 56.25 17.8967 56.25 14.5312C56.25 11.1658 53.5217 8.4375 50.1563 8.4375C46.7908 8.4375 44.0625 11.1658 44.0625 14.5312C44.0625 17.8967 46.7908 20.625 50.1563 20.625Z"
      fill="#5059C9"
    />
    <path
      d="M43.4372 23.4375H18.4378C18.1079 23.439 17.7816 23.5054 17.4773 23.6331C17.1731 23.7607 16.897 23.947 16.6649 24.1814C16.4327 24.4158 16.2489 24.6936 16.1241 24.999C15.9993 25.3044 15.9359 25.6313 15.9375 25.9612V41.1056C15.9375 45.0839 17.5179 48.8992 20.3309 51.7122C23.144 54.5253 26.9593 56.1056 30.9375 56.1056C34.9158 56.1056 38.7311 54.5253 41.5441 51.7122C44.3572 48.8992 45.9375 45.0839 45.9375 41.1056V25.9612C45.9391 25.6313 45.8757 25.3044 45.7509 24.999C45.6261 24.6936 45.4424 24.4158 45.2102 24.1814C44.978 23.947 44.7019 23.7607 44.3977 23.6331C44.0935 23.5054 43.7671 23.439 43.4372 23.4375Z"
      fill="#7B83EB"
    />
    <path
      d="M30.9375 20.625C35.5974 20.625 39.375 16.8474 39.375 12.1875C39.375 7.5276 35.5974 3.75 30.9375 3.75C26.2776 3.75 22.5 7.5276 22.5 12.1875C22.5 16.8474 26.2776 20.625 30.9375 20.625Z"
      fill="#7B83EB"
    />
    <path
      opacity="0.2"
      d="M18.4378 23.4375C18.1079 23.439 17.7816 23.5054 17.4773 23.6331C17.1731 23.7607 16.897 23.947 16.6649 24.1814C16.4327 24.4158 16.2489 24.6936 16.1241 24.999C15.9993 25.3044 15.9359 25.6313 15.9375 25.9613V41.1056C15.9386 43.4318 16.4746 45.7265 17.5041 47.8125H28.4803C29.0597 47.8137 29.6207 47.6089 30.0631 47.2347C30.5054 46.8605 30.8004 46.3413 30.8953 45.7697C30.9256 45.6305 30.9398 45.4883 30.9375 45.3459V23.4375H18.4378Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M30.9374 20.625V17.4666C30.9387 17.1431 30.876 16.8226 30.7531 16.5234C30.6301 16.2242 30.4493 15.9522 30.221 15.723C29.9928 15.4938 29.7215 15.312 29.4227 15.1879C29.124 15.0639 28.8037 15 28.4802 15H22.9902C23.5714 16.6438 24.6475 18.0672 26.0706 19.0744C27.4937 20.0817 29.1939 20.6234 30.9374 20.625Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M31.875 23.4375H18.4378C18.1079 23.439 17.7816 23.5054 17.4773 23.6331C17.1731 23.7607 16.897 23.947 16.6649 24.1814C16.4327 24.4158 16.2489 24.6936 16.1241 24.999C15.9993 25.3044 15.9359 25.6313 15.9375 25.9612V41.1056C15.9364 43.0842 16.3214 45.0439 17.071 46.875H28.3857C29.2831 46.871 30.1438 46.518 30.7857 45.8906C31.1301 45.5632 31.4042 45.1691 31.5915 44.7323C31.7788 44.2955 31.8753 43.8252 31.875 43.35V23.4375Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M30.9377 20.625C31.251 20.6236 31.564 20.6052 31.8752 20.5697V17.5875C31.8768 16.6583 31.5107 15.7662 30.857 15.1058C30.2033 14.4455 29.315 14.0704 28.3859 14.0625H22.7168C23.1414 15.9257 24.186 17.5895 25.6794 18.7817C27.1729 19.9738 29.0268 20.6237 30.9377 20.625Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M30.9374 20.625V17.4853C30.9398 17.1606 30.878 16.8387 30.7557 16.538C30.6334 16.2372 30.4529 15.9635 30.2247 15.7326C29.9964 15.5018 29.7248 15.3182 29.4255 15.1925C29.1261 15.0668 28.8049 15.0013 28.4802 15H22.9902C23.5714 16.6438 24.6475 18.0672 26.0706 19.0744C27.4937 20.0817 29.1939 20.6234 30.9374 20.625Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M18.4378 23.4375C18.1079 23.439 17.7816 23.5054 17.4773 23.6331C17.1731 23.7607 16.897 23.947 16.6649 24.1814C16.4327 24.4158 16.2489 24.6936 16.1241 24.999C15.9993 25.3044 15.9359 25.6313 15.9375 25.9613V41.1056C15.9394 42.7485 16.2072 44.3802 16.7307 45.9375H28.4803C28.805 45.9362 29.1262 45.8707 29.4256 45.745C29.7249 45.6193 29.9965 45.4357 30.2248 45.2049C30.4531 44.974 30.6335 44.7003 30.7558 44.3996C30.8781 44.0988 30.9399 43.7769 30.9375 43.4522V23.4375H18.4378Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M18.4378 23.4375C18.1079 23.439 17.7816 23.5054 17.4773 23.6331C17.1731 23.7607 16.897 23.947 16.6649 24.1814C16.4327 24.4158 16.2489 24.6936 16.1241 24.999C15.9993 25.3044 15.9359 25.6313 15.9375 25.9613V41.1056C15.9394 42.7485 16.2072 44.3802 16.7307 45.9375H27.5241C27.85 45.9374 28.1727 45.8729 28.4736 45.7479C28.7746 45.6228 29.0479 45.4396 29.2779 45.2087C29.5079 44.9778 29.6901 44.7038 29.814 44.4024C29.9379 44.101 30.0011 43.7781 30 43.4522V23.4375H18.4378Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.9999 20.5697V17.4853C30.001 17.1594 29.9378 16.8365 29.8139 16.5351C29.69 16.2337 29.5078 15.9597 29.2778 15.7288C29.0478 15.4979 28.7745 15.3147 28.4735 15.1896C28.1726 15.0646 27.8499 15.0001 27.524 15H22.9902C23.5184 16.4925 24.4558 17.8063 25.6954 18.7913C26.935 19.7762 28.4266 20.3925 29.9999 20.5697Z"
      fill="black"
    />
    <path
      d="M27.4997 15H2.50031C1.11943 15 0 16.1194 0 17.5003V42.4997C0 43.8806 1.11943 45 2.50031 45H27.4997C28.8806 45 30 43.8806 30 42.4997V17.5003C30 16.1194 28.8806 15 27.4997 15Z"
      fill="url(#paint0_linear_1415_942)"
    />
    <path
      d="M21.4442 24.225H16.5814V38.4375H13.4876V24.225H8.64355V21.5625H21.4442V24.225Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1415_942"
        x1="5.2125"
        y1="13.0472"
        x2="24.7875"
        y2="46.9528"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop offset="0.5" stopColor="#4D55BD" />
        <stop offset="1" stopColor="#3940AB" />
      </linearGradient>
    </defs>
  </svg>
);

export const layer3Icon3 = (
    <svg role="img" aria-labelledby="layer3Icon3"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer3Icon3">Microsoft Windows icon</title>
    <rect x="3" y="31.3501" width="25.65" height="25.65" fill="#0078D4" />
    <rect x="31.3501" y="31.3501" width="25.65" height="25.65" fill="#0078D4" />
    <rect x="31.3501" y="3" width="25.65" height="25.65" fill="#0078D4" />
    <rect x="3" y="3" width="25.65" height="25.65" fill="#0078D4" />
  </svg>
);

export const layer3Icon4 = (
    <svg role="img" aria-labelledby="layer3Icon4"
     width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer3Icon4">Microsoft Edge browser icon</title>
    <path
      d="M54.1449 44.6528C53.3425 45.0685 52.5169 45.4382 51.6724 45.7599C48.981 46.7639 46.1311 47.2764 43.2585 47.2729C32.1875 47.2729 22.5189 39.6432 22.5189 29.8546C22.5327 28.5416 22.8964 27.2561 23.5727 26.1306C24.2489 25.0051 25.2132 24.0804 26.366 23.4519C16.3376 23.8763 13.7544 34.3291 13.7544 40.455C13.7544 57.7626 29.715 59.5155 33.1286 59.5155C35.2748 59.4334 37.4017 59.0768 39.4574 58.4545L39.7711 58.3438C46.2245 56.1095 51.726 51.7466 55.3719 45.9721C55.4991 45.798 55.5613 45.585 55.5478 45.3698C55.5343 45.1547 55.4459 44.9511 55.2979 44.7944C55.1499 44.6377 54.9518 44.5377 54.7378 44.5118C54.5238 44.4859 54.3075 44.5358 54.1264 44.6528H54.1449Z"
      fill="url(#paint0_linear_1415_959)"
    />
    <path
      opacity="0.35"
      d="M54.1449 44.6528C53.3425 45.0685 52.5169 45.4382 51.6724 45.7599C48.981 46.7639 46.1311 47.2764 43.2585 47.2729C32.1875 47.2729 22.5189 39.6432 22.5189 29.8546C22.5327 28.5416 22.8964 27.2561 23.5727 26.1306C24.2489 25.0051 25.2132 24.0804 26.366 23.4519C16.3376 23.8763 13.7544 34.3291 13.7544 40.455C13.7544 57.7626 29.715 59.5155 33.1286 59.5155C35.2748 59.4334 37.4017 59.0768 39.4574 58.4545L39.7711 58.3438C46.2245 56.1095 51.726 51.7466 55.3719 45.9721C55.4991 45.798 55.5613 45.585 55.5478 45.3698C55.5343 45.1547 55.4459 44.9511 55.2979 44.7944C55.1499 44.6377 54.9518 44.5377 54.7378 44.5118C54.5238 44.4859 54.3075 44.5358 54.1264 44.6528H54.1449Z"
      fill="url(#paint1_radial_1415_959)"
    />
    <path
      d="M24.7729 56.5724C22.6851 55.2742 20.8757 53.5746 19.4496 51.5721C17.9844 49.5659 16.9288 47.2907 16.343 44.8765C15.7573 42.4623 15.653 39.9564 16.0359 37.5018C16.4189 35.0473 17.2816 32.6922 18.575 30.5712C19.8683 28.4501 21.5668 26.6047 23.5735 25.1402C24.4478 24.5018 25.3735 23.9371 26.3412 23.4519C27.4627 22.852 28.7137 22.5353 29.9854 22.5293C31.9514 22.5408 33.8355 23.318 35.2376 24.6961C36.6398 26.0741 37.4496 27.9445 37.4952 29.9099C37.4952 29.8546 43.2244 11.2554 18.7484 11.2554C8.46165 11.2554 0.00161934 21.0163 0.00161934 29.5778C-0.0451701 34.1104 0.922544 38.596 2.83391 42.7061C5.96385 49.3523 11.4228 54.6196 18.1765 57.5102C24.9303 60.4008 32.5097 60.7138 39.4787 58.3899C37.0396 59.1621 34.4622 59.3969 31.9237 59.0784C29.3851 58.7599 26.9457 57.8956 24.7729 56.5448V56.5724Z"
      fill="url(#paint2_linear_1415_959)"
    />
    <path
      opacity="0.41"
      d="M24.7729 56.5724C22.6851 55.2742 20.8757 53.5746 19.4496 51.5721C17.9844 49.5659 16.9288 47.2907 16.343 44.8765C15.7573 42.4623 15.653 39.9564 16.0359 37.5018C16.4189 35.0473 17.2816 32.6922 18.575 30.5712C19.8683 28.4501 21.5668 26.6047 23.5735 25.1402C24.4478 24.5018 25.3735 23.9371 26.3412 23.4519C27.4627 22.852 28.7137 22.5353 29.9854 22.5293C31.9514 22.5408 33.8355 23.318 35.2376 24.6961C36.6398 26.0741 37.4496 27.9445 37.4952 29.9099C37.4952 29.8546 43.2244 11.2554 18.7484 11.2554C8.46165 11.2554 0.00161934 21.0163 0.00161934 29.5778C-0.0451701 34.1104 0.922544 38.596 2.83391 42.7061C5.96385 49.3523 11.4228 54.6196 18.1765 57.5102C24.9303 60.4008 32.5097 60.7138 39.4787 58.3899C37.0396 59.1621 34.4622 59.3969 31.9237 59.0784C29.3851 58.7599 26.9457 57.8956 24.7729 56.5448V56.5724Z"
      fill="url(#paint3_radial_1415_959)"
    />
    <path
      d="M35.699 34.8827C35.5052 35.1318 34.924 35.4732 34.924 36.2112C34.9578 36.5625 35.0762 36.9004 35.269 37.196C35.4618 37.4916 35.7233 37.7362 36.0311 37.9088C39.3985 40.2521 45.7551 39.9384 45.7735 39.9384C48.2703 39.9293 50.7194 39.2541 52.8682 37.9826C55.032 36.7161 56.828 34.9071 58.0787 32.7341C59.3294 30.5612 59.9915 28.0995 59.9997 25.5923C60.0172 21.9915 59.1018 18.4475 57.3427 15.3056C52.37 5.59083 41.6496 1.37836e-07 29.9974 1.37836e-07C22.1142 -0.000755104 14.5476 3.10216 8.93459 8.63747C3.32158 14.1728 0.113513 21.6954 0.00439453 29.5779C0.115104 21.0163 8.63049 14.097 18.7512 14.097C22.1684 14.1337 25.5333 14.941 28.5951 16.4588C31.6325 17.9682 34.1485 20.3524 35.8189 23.3043C36.933 25.4411 37.5183 27.8139 37.5257 30.2237C37.4034 31.926 36.7663 33.5509 35.699 34.8827Z"
      fill="url(#paint4_radial_1415_959)"
    />
    <path
      d="M35.699 34.8827C35.5052 35.1318 34.924 35.4732 34.924 36.2112C34.9578 36.5625 35.0762 36.9004 35.269 37.196C35.4618 37.4916 35.7233 37.7362 36.0311 37.9088C39.3985 40.2521 45.7551 39.9384 45.7735 39.9384C48.2703 39.9293 50.7194 39.2541 52.8682 37.9826C55.032 36.7161 56.828 34.9071 58.0787 32.7341C59.3294 30.5612 59.9915 28.0995 59.9997 25.5923C60.0172 21.9915 59.1018 18.4475 57.3427 15.3056C52.37 5.59083 41.6496 1.37836e-07 29.9974 1.37836e-07C22.1142 -0.000755104 14.5476 3.10216 8.93459 8.63747C3.32158 14.1728 0.113513 21.6954 0.00439453 29.5779C0.115104 21.0163 8.63049 14.097 18.7512 14.097C22.1684 14.1337 25.5333 14.941 28.5951 16.4588C31.6325 17.9682 34.1485 20.3524 35.8189 23.3043C36.933 25.4411 37.5183 27.8139 37.5257 30.2237C37.4034 31.926 36.7663 33.5509 35.699 34.8827Z"
      fill="url(#paint5_radial_1415_959)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1415_959"
        x1="13.7544"
        y1="41.4883"
        x2="55.538"
        y2="41.4883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D5AA4" />
        <stop offset="1" stopColor="#114B8B" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1415_959"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(36.8373 41.8177) scale(22.3541 21.2364)"
      >
        <stop offset="0.72" stopColor="#010101" stopOpacity="0" />
        <stop offset="0.95" stopColor="#010101" stopOpacity="0.53" />
        <stop offset="1" stopColor="#010101" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_1415_959"
        x1="35.8069"
        y1="23.3781"
        x2="9.70713"
        y2="51.8027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2F9CD7" />
        <stop offset="0.16" stopColor="#3093D1" />
        <stop offset="0.67" stopColor="#2E7EC1" />
        <stop offset="1" stopColor="#2F76BC" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_1415_959"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5407 46.6361) rotate(-81.3844) scale(33.607 27.1511)"
      >
        <stop offset="0.76" stopColor="#010101" stopOpacity="0" />
        <stop offset="0.95" stopColor="#010101" stopOpacity="0.5" />
        <stop offset="1" stopColor="#010101" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_1415_959"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.05744 11.1023) rotate(92.2906) scale(47.4769 101.116)"
      >
        <stop stopColor="#39C1F1" />
        <stop offset="0.11" stopColor="#38C1EC" />
        <stop offset="0.23" stopColor="#30C2DF" />
        <stop offset="0.31" stopColor="#35C1D0" />
        <stop offset="0.67" stopColor="#50B855" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_1415_959"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(56.2551 18.1364) rotate(73.7398) scale(22.8061 18.546)"
      >
        <stop stopColor="#81C566" />
        <stop offset="1" stopColor="#81C566" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const layer3Icon5 = (
    <svg role="img" aria-labelledby="layer3Icon5"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer3Icon5">Microsoft Office app icon</title>
    <path
      d="M32.1572 1.9895L48.4135 6.46888C49.5889 6.80286 50.6235 7.5105 51.361 8.48478C52.0985 9.45905 52.4986 10.647 52.501 11.8689V48.1314C52.5023 49.3542 52.1036 50.5439 51.3655 51.5189C50.6275 52.4939 49.5908 53.2007 48.4135 53.5314L32.1572 58.1814L35.626 46.8751V12.0564L32.1572 1.9895Z"
      fill="url(#paint0_linear_1422_1065)"
    />
    <path
      d="M30.3956 1.68726C29.4884 1.68732 28.5968 1.92315 27.8081 2.37163L10.35 12.3185C9.49181 12.8128 8.77749 13.5226 8.27765 14.3776C7.77781 15.2325 7.50976 16.2032 7.5 17.1935V44.0623C7.5 44.8082 7.79632 45.5236 8.32376 46.051C8.85121 46.5784 9.56658 46.8748 10.3125 46.8748C10.7857 46.8748 11.2509 46.752 11.6625 46.5185L16.5562 43.856C17.2211 43.4954 17.776 42.9618 18.1622 42.3115C18.5484 41.6612 18.7515 40.9186 18.75 40.1623V21.0935C18.748 20.2235 19.0156 19.3743 19.5158 18.6625C20.0161 17.9507 20.7245 17.4113 21.5437 17.1185L35.625 12.056V6.91476C35.6245 5.52816 35.0733 4.19854 34.0927 3.21824C33.112 2.23795 31.7822 1.68726 30.3956 1.68726Z"
      fill="url(#paint1_linear_1422_1065)"
    />
    <path
      d="M35.6242 53.3381V46.875H18.093C17.5566 46.8745 17.0352 47.0518 16.6103 47.3791C16.1854 47.7063 15.8809 48.1652 15.7444 48.6839C15.6079 49.2026 15.6472 49.7519 15.856 50.2459C16.0648 50.7399 16.4315 51.1508 16.8986 51.4144L27.5373 57.4369L27.8373 57.6131C28.7726 58.1249 29.828 58.3767 30.8936 58.3425C31.3195 58.3347 31.7434 58.28 32.1573 58.1794C33.1681 57.835 34.0458 57.1831 34.6675 56.315C35.2892 55.4468 35.6237 54.4059 35.6242 53.3381Z"
      fill="url(#paint2_linear_1422_1065)"
    />
    <path
      d="M30.3956 1.68726C29.4884 1.68732 28.5968 1.92315 27.8081 2.37163L10.35 12.3185C9.49181 12.8128 8.77749 13.5226 8.27765 14.3776C7.77781 15.2325 7.50976 16.2032 7.5 17.1935V44.0623C7.5 44.8082 7.79632 45.5236 8.32376 46.051C8.85121 46.5784 9.56658 46.8748 10.3125 46.8748C10.7857 46.8748 11.2509 46.752 11.6625 46.5185L16.5562 43.856C17.2211 43.4954 17.776 42.9618 18.1622 42.3115C18.5484 41.6612 18.7515 40.9186 18.75 40.1623V21.0935C18.748 20.2235 19.0156 19.3743 19.5158 18.6625C20.0161 17.9507 20.7245 17.4113 21.5437 17.1185L35.625 12.056V6.91476C35.6245 5.52816 35.0733 4.19854 34.0927 3.21824C33.112 2.23795 31.7822 1.68726 30.3956 1.68726Z"
      fill="url(#paint3_linear_1422_1065)"
    />
    <path
      d="M35.6242 53.3381V46.875H18.093C17.5566 46.8745 17.0352 47.0518 16.6103 47.3791C16.1854 47.7063 15.8809 48.1652 15.7444 48.6839C15.6079 49.2026 15.6472 49.7519 15.856 50.2459C16.0648 50.7399 16.4315 51.1508 16.8986 51.4144L27.5373 57.4369L27.8373 57.6131C28.7726 58.1249 29.828 58.3767 30.8936 58.3425C31.3195 58.3347 31.7434 58.28 32.1573 58.1794C33.1681 57.835 34.0458 57.1831 34.6675 56.315C35.2892 55.4468 35.6237 54.4059 35.6242 53.3381Z"
      fill="url(#paint4_linear_1422_1065)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1422_1065"
        x1="41.9597"
        y1="-1.303"
        x2="42.5241"
        y2="54.1332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE00" />
        <stop offset="0.05" stopColor="#FABB00" />
        <stop offset="0.201" stopColor="#EB8401" />
        <stop offset="0.333" stopColor="#E15C01" />
        <stop offset="0.436" stopColor="#DA4401" />
        <stop offset="0.5" stopColor="#D83B01" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1422_1065"
        x1="32.3681"
        y1="0.993506"
        x2="2.64563"
        y2="43.4398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C0700" />
        <stop offset="0.6" stopColor="#C72127" />
        <stop offset="0.645" stopColor="#CA2B3D" />
        <stop offset="0.765" stopColor="#D04374" />
        <stop offset="0.868" stopColor="#D5559C" />
        <stop offset="0.95" stopColor="#D860B4" />
        <stop offset="1" stopColor="#D964BD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1422_1065"
        x1="17.2642"
        y1="52.6088"
        x2="53.928"
        y2="52.6088"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA2C46" />
        <stop offset="0.6" stopColor="#B0080B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1422_1065"
        x1="32.9756"
        y1="0.125381"
        x2="26.6812"
        y2="9.11413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.4" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1422_1065"
        x1="42.2317"
        y1="53.4131"
        x2="25.7711"
        y2="51.9731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.4" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
