import { Banner } from "./Banner/Banner";
import { DesignDay } from "./DesignDay/DesignDay";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { JobApply } from "./JobApply/JobApply";
import React from "react";
import { StudioCulture } from "./StudioCulture/StudioCulture";
import { StudioJobs } from "./StudioJobs/StudioJobs";
import { StudioLocations } from "./StudioLocations/StudioLocations";
import { Welcome } from "./Welcome/Welcome";
import { DesignChallege } from "./DesignChallenge/DesignChallenge";

export const Home = () => {
  return (
    <>
      <Header />
      <Banner />
          <main id="main">
        <Welcome />
        <StudioJobs />
        <JobApply />
        <DesignDay />
        <StudioLocations />
        <DesignChallege/>
        <StudioCulture />
       </main>
      <Footer />
    </>
  );
};
