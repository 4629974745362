import "./studioculture.scss";
import React, { useRef, useState } from "react";

import ReactCardCarousel from "react-card-carousel";

import culture1 from "../../img/growth-mindset.png";
import culture2 from "../../img/customer-obsessed.png";
import culture3 from "../../img/bring-change.png";
import culture4 from "../../img/one-microsoft.png";
import culture5 from "../../img/diverse-inclusive.png";
import heart from "../../img/heart.png";


const studioCultureInfo = [

    {
        title: 'Growth Mindset',
        description: 'We believe that we need a culture founded in growth mindset. It  starts with a belief that everyone can grow and develop; and that anyone can change their mindset',
        image: culture1
    },
    {
        title: 'Customer Obsessed',
        description: 'We will be insatiable in our desire to learn from the outside and bring it into Microsoft, while still innovating to surprise and delight our users.',
        image: culture2
    },
    {
        title: 'Bringing change by Giving',
        description: `Giving is ingrained in our culture it's how we live our mission to empower every person on the planet to achieve more.`,
        image: culture3
    },
    {
        title: 'One Microsoft',
        description: `We will build on the ideas of others and collaborate across boundaries to bring the best of Microsoft to our customers as one.`,
        image: culture4
    },
    {
        title: 'Diverse & Inclusive',
        description: ` We will be open to learning our own biases and changing our behaviours so we can tap into the collective power of everyone
                       at Microsoft.`,
        image: culture5
    }

]


export const StudioCulture = () => {

    const [currentItem, setCurrentItem] = useState(0);
    let Carousel = useRef<typeof ReactCardCarousel>(null);

    const prev = () => {
        Carousel.current.prev();
        if (currentItem === 0) {
            setCurrentItem(studioCultureInfo.length - 1)
        }
        else {
            setCurrentItem((prevCount) => prevCount - 1)
        }
    }

    const next = () => {
        Carousel.current.next();
        if (currentItem === studioCultureInfo.length - 1) {
            setCurrentItem(0)
        }
        else {
            setCurrentItem((prevCount) => prevCount + 1)
        }
    }

    const currentIndex = () => {
        const index = Carousel.current.getCurrentIndex();
        setCurrentItem(index)
    }

    return (
        <div className="studio-culture" role="region" aria-label="Studio+91 Culture">
            <div className="studio-culture-text" >
                <img src={heart} alt="Health icon" />
                <h2>We listen and learn from the world around us</h2>
                <p>
                    We will only achieve our mission if we live our culture, making a
                    difference in the world.
                </p>
            </div>
            <div className="studio-culture-carousel" >
                <div onClick={prev} className="prevSlideBtn"></div>
                <div onClick={next} className="nextSlideBtn"></div>
                <ReactCardCarousel 
                    disable_box_shadow={true} ref={Carousel} afterChange={currentIndex} >
                       {
                        studioCultureInfo.map(function (card, index) {
                            return <div  key={index} className={currentItem === index ? 'sc_card center' : 'sc_card'}>
                                    <div className="card_content">
                                        <h3>{card.title}</h3>
                                        <p>{card.description}</p>
                                    </div>
                                    <img src={card.image} alt="studio culture card" />
                                </div>
                            })
                        }
                </ReactCardCarousel>
            </div>
        </div>
    );
};
