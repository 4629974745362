import "./studiojobs.scss";

import React from "react";
import dizzy from "../../img/dizzy.png";
import jobs from "../../img/studio_jobs.png";
import roles from "../../img/roles.png";

export const StudioJobs: React.FC = () => {
  return (
      <div className="studioJobs" role="region" aria-label="Find your dream role in Studio+91">
      <div className="roleInfo">
        <div>
          <img src={dizzy} alt="dizzy Icon" />
          <div className="roleInfoTxt">Find your dream role today!</div>
          <div className="roleInfoSubTxt">
            At studio<sup>+91</sup> we're creating dynamic experiences and
            products that reach billions. Whether you're a designer, researcher,
            storyteller, writer or engineer, we're always looking for that
            next-level talent to join our team. Is that you?
          </div>
        </div>
        <div>
          <div className="roles">
                      <img src={roles} alt="Whether you're a designer, researcher,
            or engineer, we're always looking for that
            next-level talent to join our team." />

            {/* <h2>
              <span className="design">Design </span>
              <span className="plussign">+ </span>
              <span className="research">Research </span>
              <span className="plussign">+</span>
            </h2>
            <h2 className="ux">
              User Experience <span className="plussign">+</span>
            </h2>
            <h2 className="de">
              Design Engineering <span className="plussign">+</span>
            </h2>
            <h2 className="intern">Internships</h2> */}
          </div>
        </div>
      </div>
      <div className="jobImage">
        <img src={jobs} alt="studio roles" />
      </div>
    </div>
  );
};
