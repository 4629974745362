import "./banner.scss";

import React from "react";
import ReactPlayer from "react-player";
import bannerMobile from "../../img/banner-mobile.png";
import bannerVideo from "../../img/animated-banner-new.mp4";
import bannerVideoMobile from "../../img/animated-banner-phone.mp4";
import { isMobile } from "react-device-detect";

export const Banner: React.FC = () => {
  // const videoSource = isMobile ? bannerVideoMobile : bannerVideo;
  return (
    <div className="banner">
      <div className="bannerVideo">
        <ReactPlayer
          playing
          autoPlay
          muted
          loop
          url={bannerVideo}
          width="100%"
          height="100%"
        >
          Your browser does not support the video tag.
        </ReactPlayer>
      </div>
      {/* <video height="490px" autoPlay>
        <source src={bannerVideoMobile} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <div className="mobileBanner">
        {/* <img src={bannerMobile}  alt="studio 91 banner" /> */}
        <ReactPlayer
          playing
          autoPlay
          muted
          loop
          url={bannerVideoMobile}
          width="100%"
          height="100%"
        >
          Your browser does not support the video tag.
        </ReactPlayer>
      </div>
    </div>
  );
};
