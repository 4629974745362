import "./jobapply.scss";

import React from "react";
import mailIcon from "../../img/mail.png";

export const JobApply: React.FC = () => {
  return (
      <div className="jobApply" id="joinUs" role="region" aria-label="Apply for job in Studio+91">
      <div className="jobCard">
        <img src={mailIcon} alt="Mail to icon" />
        <h2>
          We are on a mission to empower every person and organization on this
          planet to achieve more.
          <br />
          <br />
          If this piques your interest,
        </h2>
        <a
          href="mailto:studio91careers@microsoft.com"
          className="applyButton"
                  target="_blank"
                  aria-label="Job Apply Button"
        >
          <div>Apply</div>
          <span>*Resume and portfolio required </span>
        </a>
        <a
          href="https://careers.microsoft.com/professionals/us/en/search-results?subCategory=Design"
          className="viewOpenLink"
          target="_blank"
        >
          View all open roles &gt;
        </a>
      </div>
    </div>
  );
};
