import * as React from "react";

export const layer2Icon1 = (
    <svg role="img" aria-labelledby="layer2Icon1"   
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon1">Microsoft Bing app icon</title>
    <g clipPath="url(#clip0_1423_960)">
      <path
        d="M50.4543 38.5183C50.4541 42.613 48.8818 46.5557 46.0537 49.5535C46.3508 49.2283 46.6176 48.8775 46.8509 48.5052C47.0322 48.2216 47.1915 47.9249 47.3275 47.6176C47.3769 47.5164 47.4206 47.4126 47.4586 47.3066C47.5027 47.2051 47.5412 47.1013 47.5737 46.9957C47.6109 46.8978 47.6428 46.7965 47.6712 46.6951C47.6995 46.5938 47.7296 46.4855 47.7527 46.3789L47.7651 46.3422C47.7899 46.2374 47.8094 46.1325 47.8306 46.026C47.8519 45.9194 47.8714 45.8076 47.8891 45.6975C47.905 45.5962 47.9174 45.4948 47.9263 45.39C47.9513 45.1503 47.9637 44.9094 47.9635 44.6684C47.9674 43.3455 47.6034 42.0469 46.9112 40.9137C46.7601 40.6596 46.5914 40.4162 46.4063 40.1851C46.19 39.9101 45.9531 39.6513 45.6976 39.4111C45.0622 38.8026 44.3204 38.3125 43.508 37.9645C43.1521 37.8093 42.7835 37.6842 42.4061 37.5906H42.3901L42.259 37.5469L40.344 36.8987L35.3358 35.2004C35.3198 35.2004 35.2986 35.2004 35.2862 35.2004L34.9726 35.0869C33.9676 34.6936 33.1331 33.967 32.6129 33.0322L30.79 28.4231L28.6942 23.1536L28.2921 22.1367L28.1893 21.9306C28.0721 21.649 28.0119 21.3474 28.0122 21.043C28.0077 20.9644 28.0077 20.8857 28.0122 20.8071C28.0498 20.4487 28.1721 20.1039 28.3693 19.8005C28.5665 19.4971 28.8331 19.2436 29.1477 19.0602C29.4623 18.8769 29.8161 18.7689 30.1807 18.7449C30.5453 18.7209 30.9105 18.7815 31.247 18.9219L40.5814 23.6393L42.422 24.5671C43.3966 25.138 44.3075 25.8085 45.1396 26.5676C48.2407 29.369 50.1304 33.2395 50.4171 37.3774C50.4383 37.76 50.4543 38.1374 50.4543 38.5183Z"
        fill="url(#paint0_linear_1423_960)"
      />
      <path
        d="M47.9586 44.6613C47.9586 45.1196 47.9159 45.577 47.8311 46.0276C47.8063 46.1447 47.7815 46.2635 47.7531 46.377C47.7 46.5867 47.6433 46.7894 47.5759 46.9938C47.5387 47.0986 47.5015 47.2035 47.4608 47.3048C47.4201 47.4061 47.374 47.511 47.3297 47.6158C47.1937 47.9231 47.0344 48.2198 46.8532 48.5034C46.6195 48.8754 46.3527 49.2262 46.056 49.5517C44.6954 51.035 40.0769 53.6803 38.3745 54.7565L34.5957 57.0279C31.8268 58.7104 29.2084 59.902 25.9151 59.9824C25.7592 59.9824 25.6068 59.9824 25.4545 59.9824C25.2419 59.9824 25.0311 59.9824 24.822 59.9701C22.1553 59.8689 19.5536 59.1294 17.2413 57.8154C14.929 56.5013 12.9755 54.6522 11.5495 52.4275C10.2341 50.3851 9.40544 48.075 9.12598 45.6712C9.32913 46.7892 9.79809 47.8437 10.4944 48.7484C11.1907 49.6531 12.0945 50.3821 13.1317 50.8757C14.169 51.3692 15.31 51.6133 16.4614 51.5879C17.6127 51.5624 18.7416 51.2682 19.7553 50.7293L19.7801 50.717L20.1504 50.4986L21.6545 49.6251L23.5695 48.5069V48.4544L23.8158 48.3094L40.9485 38.2998L42.2666 37.5293L42.3977 37.573H42.4136C42.7911 37.6662 43.1598 37.7913 43.5155 37.9469C44.3279 38.2949 45.0697 38.785 45.7052 39.3935C45.9606 39.6338 46.1975 39.8925 46.4138 40.1675C46.5987 40.3988 46.7674 40.6422 46.9187 40.8961C47.6065 42.0344 47.9661 43.3363 47.9586 44.6613V44.6613Z"
        fill="url(#paint1_linear_1423_960)"
      />
      <path
        d="M23.5696 11.2534V48.5191L21.6474 49.6303L20.1416 50.5039L19.7713 50.7258C19.7628 50.7269 19.7547 50.7305 19.7483 50.7362C18.7347 51.2751 17.606 51.5694 16.4547 51.595C15.3035 51.6205 14.1625 51.3766 13.1253 50.8832C12.0881 50.3898 11.1843 49.661 10.4879 48.7566C9.79146 47.8521 9.32232 46.7977 9.11893 45.6799C9.09059 45.5244 9.06933 45.3637 9.05161 45.2082C9.02858 44.9146 9.01264 44.6264 9.00732 44.3346V2.4022C9.00773 1.966 9.12837 1.53812 9.35634 1.16433C9.58431 0.790537 9.91104 0.484904 10.3016 0.280128C10.6921 0.0753519 11.1317 -0.0208618 11.5734 0.00177867C12.0151 0.0244191 12.4422 0.165062 12.8091 0.408662L20.2621 5.20992C20.3027 5.24299 20.3459 5.27279 20.3914 5.29903C21.3735 5.96046 22.1766 6.84889 22.7306 7.88678C23.2846 8.92468 23.5726 10.0805 23.5696 11.2534V11.2534Z"
        fill="url(#paint2_linear_1423_960)"
      />
      <g opacity="0.149">
        <g opacity="0.149">
          <path
            opacity="0.149"
            d="M47.9586 44.6613C47.9586 45.1196 47.9159 45.577 47.8311 46.0276C47.8063 46.1447 47.7815 46.2635 47.7531 46.377C47.7 46.5867 47.6433 46.7894 47.5759 46.9938C47.5387 47.0986 47.5015 47.2035 47.4608 47.3048C47.4201 47.4061 47.374 47.511 47.3297 47.6158C47.1937 47.9231 47.0344 48.2198 46.8532 48.5034C46.6198 48.8756 46.353 49.2265 46.056 49.5517C44.6954 51.035 40.0769 53.6803 38.3745 54.7565L34.5957 57.0279C31.8268 58.7104 29.2084 59.902 25.9151 59.9824C25.7592 59.9824 25.6068 59.9824 25.4545 59.9824C25.2419 59.9824 25.0311 59.9824 24.822 59.9701C22.1553 59.8689 19.5536 59.1294 17.2413 57.8154C14.929 56.5013 12.9755 54.6522 11.5495 52.4275C10.2341 50.3851 9.40544 48.075 9.12598 45.6712C9.32913 46.7892 9.79809 47.8437 10.4944 48.7484C11.1907 49.6531 12.0945 50.3821 13.1317 50.8757C14.169 51.3692 15.31 51.6133 16.4614 51.5879C17.6127 51.5624 18.7416 51.2682 19.7553 50.7293L19.7801 50.717L20.1504 50.4986L21.6545 49.6251L23.5695 48.5069V48.4544L23.8158 48.3094L40.9485 38.2998L42.2666 37.5293L42.3977 37.573H42.4136C42.7911 37.6662 43.1598 37.7913 43.5155 37.9469C44.3279 38.2949 45.0697 38.785 45.7052 39.3935C45.9606 39.6338 46.1975 39.8925 46.4138 40.1675C46.5987 40.3988 46.7674 40.6422 46.9187 40.8961C47.6065 42.0344 47.9661 43.3363 47.9586 44.6613V44.6613Z"
            fill="url(#paint3_linear_1423_960)"
          />
        </g>
      </g>
      <g opacity="0.098">
        <g opacity="0.098">
          <path
            opacity="0.098"
            d="M23.5696 11.2534V48.5191L21.6474 49.6303L20.1416 50.5039L19.7713 50.7258C19.7628 50.7269 19.7547 50.7305 19.7483 50.7362C18.7347 51.2751 17.606 51.5694 16.4547 51.595C15.3035 51.6205 14.1625 51.3766 13.1253 50.8832C12.0881 50.3898 11.1843 49.661 10.4879 48.7566C9.79146 47.8521 9.32232 46.7977 9.11893 45.6799C9.09059 45.5244 9.06933 45.3637 9.05161 45.2082C9.02858 44.9146 9.01264 44.6264 9.00732 44.3346V2.4022C9.00773 1.966 9.12837 1.53812 9.35634 1.16433C9.58431 0.790537 9.91104 0.484904 10.3016 0.280128C10.6921 0.0753519 11.1317 -0.0208618 11.5734 0.00177867C12.0151 0.0244191 12.4422 0.165062 12.8091 0.408662L20.2621 5.20992C20.3027 5.24299 20.3459 5.27279 20.3914 5.29903C21.3735 5.96046 22.1766 6.84889 22.7306 7.88678C23.2846 8.92468 23.5726 10.0805 23.5696 11.2534V11.2534Z"
            fill="url(#paint4_linear_1423_960)"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1423_960"
        x1="24.7698"
        y1="26.4944"
        x2="51.2652"
        y2="42.0203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#37BDFF" />
        <stop offset="0.18" stopColor="#33BFFD" />
        <stop offset="0.36" stopColor="#28C5F5" />
        <stop offset="0.53" stopColor="#15D0E9" />
        <stop offset="0.55" stopColor="#12D1E7" />
        <stop offset="0.59" stopColor="#1CD2E5" />
        <stop offset="0.77" stopColor="#42D8DC" />
        <stop offset="0.91" stopColor="#59DBD6" />
        <stop offset="1" stopColor="#62DCD4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1423_960"
        x1="9.13359"
        y1="49.306"
        x2="47.9869"
        y2="49.306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#39D2FF" />
        <stop offset="0.15" stopColor="#38CEFE" />
        <stop offset="0.29" stopColor="#35C3FA" />
        <stop offset="0.43" stopColor="#2FB0F3" />
        <stop offset="0.55" stopColor="#299AEB" />
        <stop offset="0.58" stopColor="#2692EC" />
        <stop offset="0.76" stopColor="#1A6CF1" />
        <stop offset="0.91" stopColor="#1355F4" />
        <stop offset="1" stopColor="#104CF5" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1423_960"
        x1="16.3239"
        y1="52.6381"
        x2="16.3239"
        y2="1.41845"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B48EF" />
        <stop offset="0.12" stopColor="#1C51F0" />
        <stop offset="0.32" stopColor="#1E69F5" />
        <stop offset="0.57" stopColor="#2190FB" />
        <stop offset="1" stopColor="#26B8F4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1423_960"
        x1="16.8436"
        y1="58.7468"
        x2="40.5081"
        y2="34.7955"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.37" stopColor="#FDFDFD" />
        <stop offset="0.51" stopColor="#F6F6F6" />
        <stop offset="0.6" stopColor="#EBEBEB" />
        <stop offset="0.68" stopColor="#DADADA" />
        <stop offset="0.75" stopColor="#C4C4C4" />
        <stop offset="0.81" stopColor="#A8A8A8" />
        <stop offset="0.86" stopColor="#888888" />
        <stop offset="0.91" stopColor="#626262" />
        <stop offset="0.95" stopColor="#373737" />
        <stop offset="0.99" stopColor="#090909" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1423_960"
        x1="-445.335"
        y1="34472.8"
        x2="-445.335"
        y2="33849.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.37" stopColor="#FDFDFD" />
        <stop offset="0.51" stopColor="#F6F6F6" />
        <stop offset="0.6" stopColor="#EBEBEB" />
        <stop offset="0.68" stopColor="#DADADA" />
        <stop offset="0.75" stopColor="#C4C4C4" />
        <stop offset="0.81" stopColor="#A8A8A8" />
        <stop offset="0.86" stopColor="#888888" />
        <stop offset="0.91" stopColor="#626262" />
        <stop offset="0.95" stopColor="#373737" />
        <stop offset="0.99" stopColor="#090909" />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0_1423_960">
        <rect
          width="41.4545"
          height="60"
          fill="white"
          transform="translate(9)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const layer2Icon2 = (
    <svg role="img" aria-labelledby="layer2Icon2"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon2">Microsoft Lens app icon</title>
    <g clipPath="url(#clip0_1422_978)">
      <mask
        id="mask0_1422_978"
        className="alphaMask"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="2"
        width="61"
        height="55"
      >
        <path
          d="M32.5234 56.8652C47.5042 56.8652 59.6484 44.721 59.6484 29.7402C59.6484 14.7595 47.5042 2.61523 32.5234 2.61523C23.1969 2.61523 14.9698 7.32223 10.088 14.4902H2.1901C0.792187 14.4902 -0.351562 15.634 -0.351562 17.0319V42.4486C-0.351562 43.8465 0.792187 44.9902 2.1901 44.9902H10.088C14.9698 52.1582 23.1969 56.8652 32.5234 56.8652Z"
          fill="#D83B01"
        />
      </mask>
      <g mask="url(#mask0_1422_978)">
        <mask
          id="mask1_1422_978"
          className="alphaMask"
          maskUnits="userSpaceOnUse"
          x="5"
          y="2"
          width="55"
          height="55"
        >
          <path
            d="M9.03203 43.3005C1.54291 30.3289 5.98729 13.7423 18.9588 6.25322C31.9303 -1.23589 48.5169 3.20848 56.0061 16.18C63.4952 29.1515 59.0508 45.7381 46.0793 53.2272C33.1078 60.7164 16.5211 56.272 9.03203 43.3005Z"
            fill="#731717"
          />
        </mask>
        <g mask="url(#mask1_1422_978)">
          <path
            d="M16.2721 -17.1616L-37.1079 -17.1616L-37.1079 20.3599L37.9352 20.3599L16.2721 -17.1616Z"
            fill="#F57D37"
          />
          <path
            d="M21.689 29.7398L27.1045 20.3599L37.9355 20.3599L43.351 29.7398L37.9355 39.1197H27.1045L21.689 29.7398Z"
            fill="#731717"
          />
          <path
            d="M48.7675 76.6421H102.148V39.1206H27.1045L48.7675 76.6421Z"
            fill="#D83B01"
          />
          <path
            d="M-16.2211 20.3599L-42.9111 66.5883L-10.4165 85.3491L27.105 20.3599L-16.2211 20.3599Z"
            fill="#EC6725"
          />
          <path
            d="M81.2612 39.1206L107.951 -7.10788L75.4566 -25.8687L37.9351 39.1206L81.2612 39.1206Z"
            fill="#BF3207"
          />
          <path
            d="M0.027832 67.2618L26.7178 113.49L59.2124 94.7295L21.6909 29.7402L0.027832 67.2618Z"
            fill="#E25113"
          />
          <path
            d="M65.0157 -7.78131L38.3257 -54.0098L5.83105 -35.249L43.3526 29.7402L65.0157 -7.78131Z"
            fill="#FF9349"
          />
          <g className="blendMode" opacity="0.11">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.9334 20.3599L41.5439 26.6135H-19.834L-16.2235 20.3599H37.9334Z"
              fill="url(#paint0_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.929 20.3599L38.8316 21.9233H-17.1304L-16.2278 20.36L27.0978 20.36L27.0979 20.3599H37.929Z"
              fill="url(#paint1_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.11">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.0983 39.1201L23.4878 32.8665L84.8657 32.8665L81.2552 39.1201L27.0983 39.1201Z"
              fill="url(#paint2_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.2001 37.5568L82.1621 37.5568L81.2595 39.1201L37.9337 39.1201L27.1027 39.1201L26.2001 37.5568Z"
              fill="url(#paint3_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.11">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.101 20.3609L34.322 20.3609L3.63013 73.5208L0.0196161 67.2672L27.101 20.3609Z"
              fill="url(#paint4_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.1012 20.3615L28.9065 20.3615L0.922302 68.8315L0.0196738 67.2681L27.1012 20.3615Z"
              fill="url(#paint5_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.11">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.7242 39.119L61.4128 -14.0352L65.023 -7.78222L43.3601 29.739L43.3601 29.7391L37.9446 39.119L30.7242 39.119Z"
              fill="url(#paint6_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.1348 39.1194L64.1158 -9.34516L65.0184 -7.78183L43.3555 29.7394L43.3555 29.7395L37.94 39.1194L36.1348 39.1194Z"
              fill="url(#paint7_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.11">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.6954 29.7372L25.3059 23.4836L55.9974 76.6427L48.7763 76.6427L21.6954 29.7372Z"
              fill="url(#paint8_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.693 29.7422L22.5956 28.1788L50.5771 76.6441L48.7718 76.6441L21.693 29.7422Z"
              fill="url(#paint9_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.11">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.9292 20.3603L37.9274 20.3603L43.3423 29.7392L39.7327 35.9912L9.04509 -17.1612L16.2661 -17.1612L37.9292 20.3603Z"
              fill="url(#paint10_linear_1422_978)"
            />
          </g>
          <g className="blendMode" opacity="0.24">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M43.3569 29.7449L42.4542 31.3083L14.4698 -17.1621L16.275 -17.1621L43.3569 29.7449Z"
              fill="url(#paint11_linear_1422_978)"
            />
          </g>
        </g>
        <g filter="url(#filter0_dd_1422_978)">
          <path
            d="M27.6063 44.9902H2.18962C0.791699 44.9902 -0.352051 43.8465 -0.352051 42.4486V17.0319C-0.352051 15.634 0.791699 14.4902 2.18962 14.4902H27.6063C29.0042 14.4902 30.148 15.634 30.148 17.0319V42.4486C30.148 43.8465 29.0042 44.9902 27.6063 44.9902Z"
            fill="#D83B01"
          />
        </g>
        <path
          d="M12.8158 35.3323H20.2143V37.9902H9.58252V21.4902H12.8158V35.3323Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_1422_978"
        x="-21.7521"
        y="-1.60977"
        width="73.3"
        height="73.3"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.07" />
        <feGaussianBlur stdDeviation="1.075" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1422_978"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.3" />
        <feGaussianBlur stdDeviation="10.7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1422_978"
          result="effect2_dropShadow_1422_978"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1422_978"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1422_978"
        x1="20.8903"
        y1="20.3599"
        x2="20.8903"
        y2="26.6135"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1422_978"
        x1="6.24452"
        y1="20.3599"
        x2="6.24452"
        y2="21.9233"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1422_978"
        x1="44.1414"
        y1="39.1201"
        x2="44.1414"
        y2="32.8665"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1422_978"
        x1="58.7872"
        y1="39.1201"
        x2="58.7872"
        y2="37.5568"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1422_978"
        x1="18.5784"
        y1="35.1224"
        x2="23.9942"
        y2="38.2492"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1422_978"
        x1="11.2571"
        y1="47.8043"
        x2="12.6111"
        y2="48.586"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1422_978"
        x1="46.4662"
        y1="24.3592"
        x2="41.0509"
        y2="21.2327"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1422_978"
        x1="53.7823"
        y1="11.6798"
        x2="52.4283"
        y2="10.8981"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1422_978"
        x1="30.2178"
        y1="44.4984"
        x2="35.6335"
        y2="41.3716"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1422_978"
        x1="37.5355"
        y1="57.1822"
        x2="38.8894"
        y2="56.4005"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1422_978"
        x1="34.8222"
        y1="14.9786"
        x2="29.4064"
        y2="18.1054"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1422_978"
        x1="27.5126"
        y1="2.30188"
        x2="26.1587"
        y2="3.08358"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_1422_978">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const layer2Icon3 = (
    <svg role="img" aria-labelledby="layer2Icon3"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon3">Microsoft Bookings app icon</title>
    <path
      d="M13.498 5.57143V54.4286C13.498 55.1105 13.7567 55.7646 14.217 56.2468C14.6773 56.7291 15.3016 57 15.9526 57H26.998V3H15.9526C15.3016 3 14.6773 3.27092 14.217 3.75315C13.7567 4.23539 13.498 4.88944 13.498 5.57143Z"
      fill="#057093"
    />
    <path
      d="M36.2476 20.0005H13.7476V40.0005H36.2476V20.0005Z"
      fill="#20B2C6"
    />
    <path
      d="M24.002 5.57143V54.4286C24.002 55.1105 24.2893 55.7646 24.8008 56.2468C25.3122 56.7291 26.0059 57 26.7292 57H39.002V3H26.7292C26.0059 3 25.3122 3.27092 24.8008 3.75315C24.2893 4.23539 24.002 4.88944 24.002 5.57143Z"
      fill="#1098B6"
    />
    <path d="M44.9956 26.25H24.9956V45H44.9956V26.25Z" fill="#30CCD7" />
    <path
      d="M55.9946 3H38.4946C37.1139 3 35.9946 4.15127 35.9946 5.57143V54.4286C35.9946 55.8487 37.1139 57 38.4946 57H55.9946C57.3753 57 58.4946 55.8487 58.4946 54.4286V5.57143C58.4946 4.15127 57.3753 3 55.9946 3Z"
      fill="#30CCD7"
    />
    <path
      d="M58.4946 19.5005H35.9946V40.5005H58.4946V19.5005Z"
      fill="#70ECEE"
    />
    <path
      opacity="0.1"
      d="M29.9976 13.7495H13.7476V47.4995H29.9976C30.6606 47.4995 31.2965 47.2361 31.7653 46.7673C32.2342 46.2984 32.4976 45.6625 32.4976 44.9995V16.2495C32.4976 15.5865 32.2342 14.9506 31.7653 14.4817C31.2965 14.0129 30.6606 13.7495 29.9976 13.7495Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M28.7476 15H13.7476V48.75H28.7476C29.4106 48.75 30.0465 48.4866 30.5153 48.0178C30.9842 47.5489 31.2476 46.913 31.2476 46.25V17.5C31.2476 16.837 30.9842 16.2011 30.5153 15.7322C30.0465 15.2634 29.4106 15 28.7476 15Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M28.7476 15H13.7476V46.25H28.7476C29.4106 46.25 30.0465 45.9866 30.5153 45.5178C30.9842 45.0489 31.2476 44.413 31.2476 43.75V17.5C31.2476 16.837 30.9842 16.2011 30.5153 15.7322C30.0465 15.2634 29.4106 15 28.7476 15Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M27.4976 16.2505H13.7476V46.2505H27.4976C28.1416 46.2511 28.761 46.0032 29.2267 45.5585C29.6924 45.1137 29.9686 44.5063 29.9976 43.863V18.638C29.9686 17.9946 29.6924 17.3873 29.2267 16.9425C28.761 16.4977 28.1416 16.2498 27.4976 16.2505Z"
      fill="black"
    />
    <path
      d="M27.5 15H2.5C1.11929 15 0 16.1193 0 17.5V42.5C0 43.8807 1.11929 45 2.5 45H27.5C28.8807 45 30 43.8807 30 42.5V17.5C30 16.1193 28.8807 15 27.5 15Z"
      fill="url(#paint0_linear_1426_1545)"
    />
    <path
      d="M17.7869 22.337C18.5189 22.5902 19.1615 23.0505 19.6369 23.662C20.0638 24.2308 20.2882 24.926 20.2744 25.637C20.2859 26.4894 19.9934 27.3181 19.4494 27.9745C18.8709 28.6599 18.1003 29.1563 17.2369 29.3995C17.928 29.473 18.5941 29.6993 19.1869 30.062C19.7415 30.3907 20.1988 30.8609 20.5119 31.4245C20.8214 32.0051 20.9804 32.6541 20.9744 33.312C20.9891 34.201 20.7318 35.0733 20.2369 35.812C19.7245 36.5598 19.0089 37.1453 18.1744 37.4995C17.2011 37.9059 16.154 38.1059 15.0994 38.087H9.02441V21.837H14.9119C15.8928 21.8254 16.8675 21.9949 17.7869 22.337ZM14.2119 28.587C14.9306 28.6305 15.6408 28.413 16.2119 27.9745C16.461 27.7651 16.6566 27.4995 16.7827 27.1995C16.9088 26.8996 16.9616 26.5739 16.9369 26.2495C16.9369 24.9995 16.0244 24.312 14.2119 24.312H12.1994V28.5495L14.2119 28.587ZM12.1994 35.6995H14.6994C15.4686 35.7455 16.2296 35.5199 16.8494 35.062C17.1075 34.8493 17.312 34.579 17.4466 34.2729C17.5812 33.9668 17.6421 33.6334 17.6244 33.2995C17.6428 32.9697 17.5808 32.6403 17.4436 32.3398C17.3064 32.0393 17.0982 31.7766 16.8369 31.5745C16.1513 31.1413 15.3463 30.9357 14.5369 30.987H12.1994V35.6995Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1426_1545"
        x1="5.2125"
        y1="13.05"
        x2="24.7875"
        y2="46.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007FA5" />
        <stop offset="1" stopColor="#0A6181" />
      </linearGradient>
    </defs>
  </svg>
);

export const layer2Icon4 = (
    <svg role="img" aria-labelledby="layer2Icon4"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon4">Microsoft PowerPoint app icon</title>
    <path
      d="M33.75 4C26.7943 4.02035 20.1294 6.7925 15.2109 11.7109C10.2925 16.6294 7.52035 23.2943 7.5 30.25L40.7569 37.2569L33.75 4Z"
      fill="#ED6C47"
    />
    <path
      d="M33.7686 4C40.7223 4.02108 47.3849 6.79406 52.3002 11.7129C57.2155 16.6317 59.9837 23.2963 59.9998 30.25L46.8748 39.1711L33.7686 30.25V4Z"
      fill="#FF8F6B"
    />
    <path
      d="M33.75 56.5C40.7057 56.4796 47.3706 53.7075 52.2891 48.7891C57.2075 43.8706 59.9796 37.2057 60 30.25H7.5C7.52036 37.2057 10.2925 43.8706 15.2109 48.7891C20.1294 53.7075 26.7943 56.4796 33.75 56.5Z"
      fill="#D35230"
    />
    <path
      opacity="0.1"
      d="M31.2503 13.375H13.6978C9.41177 18.4251 7.20719 24.9165 7.53127 31.5323C7.85535 38.1481 10.6838 44.3929 15.4429 49H31.2503C31.9128 48.9981 32.5475 48.734 33.0159 48.2656C33.4844 47.7972 33.7484 47.1624 33.7504 46.4999V15.875C33.7484 15.2125 33.4844 14.5778 33.0159 14.1093C32.5475 13.6409 31.9127 13.3769 31.2503 13.375Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3754 15.25H12.261C8.39613 20.7341 6.79396 27.4969 7.78768 34.132C8.78141 40.7671 12.2945 46.7638 17.5964 50.875H29.3754C30.0379 50.8731 30.6726 50.609 31.1411 50.1406C31.6095 49.6722 31.8735 49.0374 31.8755 48.3749V17.75C31.8735 17.0875 31.6095 16.4528 31.141 15.9843C30.6726 15.5159 30.0379 15.2519 29.3754 15.25Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3753 15.25H12.2609C8.93179 19.9702 7.26631 25.6631 7.52644 31.4333C7.78656 37.2035 9.95751 42.7235 13.6978 47.125H29.3753C30.0377 47.1231 30.6725 46.859 31.1409 46.3906C31.6094 45.9222 31.8734 45.2874 31.8754 44.6249V17.75C31.8734 17.0875 31.6094 16.4528 31.1409 15.9843C30.6725 15.5159 30.0377 15.2519 29.3753 15.25Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M27.5003 15.25H12.2609C8.93179 19.9702 7.26631 25.6631 7.52644 31.4333C7.78656 37.2035 9.95751 42.7235 13.6978 47.125H27.5003C28.1627 47.1231 28.7975 46.859 29.2659 46.3906C29.7344 45.9222 29.9984 45.2874 30.0004 44.6249V17.75C29.9984 17.0875 29.7344 16.4528 29.2659 15.9843C28.7975 15.5159 28.1627 15.2519 27.5003 15.25Z"
      fill="black"
    />
    <path
      d="M2.49998 15.25H27.5C28.1631 15.25 28.7989 15.5134 29.2678 15.9823C29.7366 16.4511 30 17.087 30 17.75V42.75C30 43.0783 29.9353 43.4034 29.8097 43.7067C29.6841 44.01 29.4999 44.2856 29.2678 44.5178C29.0356 44.7499 28.76 44.9341 28.4567 45.0597C28.1534 45.1853 27.8283 45.25 27.5 45.25H2.49998C1.83694 45.25 1.20106 44.9866 0.732225 44.5178C0.26339 44.0489 0 43.413 0 42.75V17.75C0 17.087 0.263389 16.4511 0.732225 15.9822C1.20106 15.5134 1.83694 15.25 2.49998 15.25Z"
      fill="#C43E1C"
    />
    <path
      d="M15.0666 20.875C16.9109 20.7509 18.7394 21.2853 20.2266 22.383C20.8366 22.9275 21.3148 23.6035 21.6249 24.3601C21.935 25.1166 22.069 25.9338 22.0166 26.7497C22.037 27.8085 21.7276 28.8475 21.1314 29.7227C20.5241 30.691 19.6498 31.4629 18.6137 31.9456C17.4297 32.495 16.1356 32.7663 14.8306 32.7387H11.2508V39.6057H7.5791V20.875H15.0666ZM11.2507 29.647H14.411C15.4125 29.72 16.406 29.4239 17.2041 28.8145C17.5213 28.6105 17.7768 28.3239 17.9432 27.9855C18.1095 27.647 18.1804 27.2696 18.1482 26.8939C18.1482 24.822 16.9418 23.7861 14.529 23.7861H11.2508L11.2507 29.647Z"
      fill="#F9F7F7"
    />
  </svg>
);
export const layer2Icon5 = (
    <svg role="img" aria-labelledby="layer2Icon5"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon5">Microsoft Excel app icon</title>
    <path
      d="M37.4992 30.2513L26.8808 26.6006L14.9985 30.2513V54.0027C14.9985 54.6656 15.2619 55.3013 15.7306 55.7701C16.1993 56.2388 16.8351 56.5021 17.498 56.5021H57.5005C58.1634 56.5021 58.7991 56.2388 59.2678 55.7701C59.7366 55.3013 59.9999 54.6656 59.9999 54.0027V43.3767L37.4992 30.2513Z"
      fill="#185C37"
    />
    <path
      d="M37.4992 4H17.498C16.8351 4 16.1993 4.26333 15.7306 4.73207C15.2619 5.20081 14.9985 5.83656 14.9985 6.49945V17.1254L37.4992 30.2508L48.7496 34.9985L59.9999 30.2508V17.1254L37.4992 4Z"
      fill="#21A366"
    />
    <path
      d="M37.4992 17.1255H14.9985V30.2509H37.4992V17.1255Z"
      fill="#107C41"
    />
    <path
      d="M57.5007 4H37.4995V17.1254H60.0002V6.49945C60.0002 5.83656 59.7369 5.20081 59.2681 4.73207C58.7994 4.26333 58.1636 4 57.5007 4Z"
      fill="#33C481"
    />
    <path
      d="M60.0002 30.2507H37.4995V43.3761H60.0002V30.2507Z"
      fill="#107C41"
    />
    <path
      opacity="0.1"
      d="M31.2497 13.3752H14.9985V49.0013H31.2497C31.9119 48.9994 32.5465 48.7354 33.0148 48.2671C33.4832 47.7988 33.7471 47.1642 33.7491 46.5019V15.8747C33.7471 15.2124 33.4832 14.5778 33.0148 14.1095C32.5465 13.6412 31.9119 13.3772 31.2497 13.3752Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3746 15.2502H14.9985V50.8763H29.3746C30.0369 50.8744 30.6715 50.6104 31.1398 50.1421C31.6081 49.6738 31.8721 49.0392 31.8741 48.3769V17.7497C31.8721 17.0874 31.6081 16.4528 31.1398 15.9845C30.6715 15.5162 30.0369 15.2522 29.3746 15.2502Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3746 15.2502H14.9985V47.1262H29.3746C30.0369 47.1242 30.6715 46.8603 31.1398 46.392C31.6081 45.9236 31.8721 45.2891 31.8741 44.6268V17.7497C31.8721 17.0874 31.6081 16.4528 31.1398 15.9845C30.6715 15.5162 30.0369 15.2522 29.3746 15.2502Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M27.4995 15.2502H14.9985V47.1262H27.4995C28.1618 47.1242 28.7964 46.8603 29.2647 46.392C29.733 45.9236 29.997 45.2891 29.999 44.6268V17.7497C29.997 17.0874 29.733 16.4528 29.2647 15.9845C28.7964 15.5162 28.1618 15.2522 27.4995 15.2502Z"
      fill="black"
    />
    <path
      d="M27.4996 15.2502H2.49945C1.11904 15.2502 0 16.3693 0 17.7497V42.7498C0 44.1302 1.11904 45.2493 2.49945 45.2493H27.4996C28.88 45.2493 29.999 44.1302 29.999 42.7498V17.7497C29.999 16.3693 28.88 15.2502 27.4996 15.2502Z"
      fill="#107C41"
    />
    <path
      d="M6.85742 39.6257L12.7488 30.4867L7.35431 21.4001H11.7007L14.6502 27.2128C14.9202 27.7641 15.1077 28.1747 15.2127 28.4466H15.2502C15.4452 28.0066 15.6489 27.5785 15.8614 27.1622L19.0134 21.4058H23.0035L17.459 30.4379L23.1404 39.6257H18.8953L15.4883 33.2505C15.3277 32.9794 15.1916 32.6945 15.0814 32.3992H15.0308C14.9308 32.6887 14.7987 32.966 14.637 33.2261L11.1288 39.6257H6.85742Z"
      fill="white"
    />
  </svg>
);
export const layer2Icon6 = (
    <svg role="img" aria-labelledby="layer2Icon6"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon6">Microsoft Word app icon</title>
    <path
      d="M57.5 3.75H17.5C16.8369 3.75 16.2011 4.01339 15.7322 4.48223C15.2634 4.95106 15 5.58693 15 6.24995V16.875L37.5 23.4375L60 16.875V6.24995C60 5.58693 59.7366 4.95106 59.2678 4.48223C58.7989 4.0134 58.1631 3.75001 57.5 3.75Z"
      fill="#41A5EE"
    />
    <path d="M60 16.875H15V30L37.5 36.5625L60 30V16.875Z" fill="#2B7CD3" />
    <path d="M60 30H15V43.125L37.5 49.6875L60 43.125V30Z" fill="#185ABD" />
    <path
      d="M60 43.125H15V53.75C15 54.413 15.2634 55.0489 15.7322 55.5177C16.201 55.9866 16.8369 56.25 17.4999 56.25H57.5C58.163 56.25 58.7989 55.9866 59.2678 55.5178C59.7366 55.0489 60 54.4131 60 53.75V43.125Z"
      fill="#103F91"
    />
    <path
      opacity="0.1"
      d="M31.2499 13.125H15V48.75H31.2499C31.9124 48.748 32.5472 48.484 33.0156 48.0156C33.484 47.5472 33.7481 46.9124 33.75 46.2499V15.625C33.748 14.9625 33.484 14.3278 33.0156 13.8593C32.5471 13.3909 31.9124 13.1269 31.2499 13.125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3749 15H15V50.625H29.3749C30.0374 50.623 30.6722 50.359 31.1406 49.8906C31.609 49.4222 31.8731 48.7874 31.875 48.1249V17.5C31.873 16.8375 31.609 16.2028 31.1406 15.7343C30.6721 15.2659 30.0374 15.0019 29.3749 15Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3749 15H15V46.875H29.3749C30.0374 46.873 30.6722 46.609 31.1406 46.1406C31.609 45.6722 31.8731 45.0374 31.875 44.3749V17.5C31.873 16.8375 31.609 16.2028 31.1406 15.7343C30.6721 15.2659 30.0374 15.0019 29.3749 15Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M27.4999 15H15V46.875H27.4999C28.1624 46.873 28.7972 46.609 29.2656 46.1406C29.734 45.6722 29.998 45.0374 30 44.3749V17.5C29.998 16.8375 29.734 16.2028 29.2656 15.7343C28.7971 15.2659 28.1624 15.0019 27.4999 15Z"
      fill="black"
    />
    <path
      d="M27.5001 15H2.49999C1.11929 15 0 16.1193 0 17.5V42.5C0 43.8807 1.11929 45 2.49999 45H27.5001C28.8808 45 30.0001 43.8807 30.0001 42.5V17.5C30.0001 16.1193 28.8808 15 27.5001 15Z"
      fill="#185ABD"
    />
    <path
      d="M22.4058 39.375H19.0308L15.0933 26.4375L10.9683 39.375H7.59326L3.84326 20.625H7.21826L9.84326 33.75L13.7808 21H16.5933L20.3433 33.75L22.9683 20.625H26.1558L22.4058 39.375Z"
      fill="white"
    />
  </svg>
);
export const layer2Icon7 = (
    <svg role="img" aria-labelledby="layer2Icon7"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon7">Microsoft SharePoint app icon</title>
    <path
      d="M28.125 35.625C37.9626 35.625 45.9375 27.6501 45.9375 17.8125C45.9375 7.97493 37.9626 0 28.125 0C18.2874 0 10.3125 7.97493 10.3125 17.8125C10.3125 27.6501 18.2874 35.625 28.125 35.625Z"
      fill="#036C70"
    />
    <path
      d="M44.7656 48.75C53.1793 48.75 60 41.9293 60 33.5156C60 25.1019 53.1793 18.2812 44.7656 18.2812C36.3519 18.2812 29.5312 25.1019 29.5312 33.5156C29.5312 41.9293 36.3519 48.75 44.7656 48.75Z"
      fill="#1A9BA1"
    />
    <path
      d="M30 60C36.731 60 42.1875 54.5435 42.1875 47.8125C42.1875 41.0815 36.731 35.625 30 35.625C23.269 35.625 17.8125 41.0815 17.8125 47.8125C17.8125 54.5435 23.269 60 30 60Z"
      fill="#37C6D0"
    />
    <path
      opacity="0.1"
      d="M31.2506 13.125H10.9369C10.2184 15.7676 10.1185 18.5402 10.6449 21.2276C11.1714 23.9151 12.31 26.445 13.9724 28.6212C15.6348 30.7974 17.7764 32.5613 20.2308 33.7759C22.6852 34.9905 25.3865 35.6233 28.125 35.625C28.6444 35.625 29.1581 35.6006 29.6662 35.5575L29.6775 35.6287C26.502 35.7128 23.4849 37.0333 21.2687 39.3091C19.0525 41.5848 17.8124 44.6359 17.8125 47.8125C17.8125 48.1275 17.8237 48.4406 17.8481 48.75H31.2506C31.9129 48.748 32.5475 48.4841 33.0158 48.0158C33.484 47.5475 33.748 46.9129 33.75 46.2506V15.6244C33.748 14.9621 33.484 14.3275 33.0158 13.8592C32.5475 13.3909 31.9129 13.127 31.2506 13.125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3756 15H10.5337C10.1291 17.5446 10.2809 20.1466 10.9786 22.6269C11.6764 25.1071 12.9036 27.4067 14.5756 29.367C16.2476 31.3274 18.3247 32.902 20.6638 33.9823C23.0029 35.0627 25.5484 35.6231 28.1249 35.625C28.6443 35.625 29.1581 35.6006 29.6662 35.5575L29.6774 35.6287C27.8657 35.6782 26.0877 36.1308 24.4728 36.9535C22.8578 37.7763 21.4465 38.9486 20.3414 40.3851C19.2364 41.8217 18.4653 43.4865 18.0842 45.2584C17.7032 47.0303 17.7218 48.8649 18.1387 50.6287H29.3756C30.0385 50.6268 30.6736 50.3623 31.142 49.8932C31.6104 49.4241 31.8739 48.7885 31.8749 48.1256V17.4994C31.873 16.8371 31.609 16.2025 31.1407 15.7342C30.6724 15.2659 30.0378 15.002 29.3756 15Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M29.3756 15H10.5337C10.1291 17.5446 10.2809 20.1466 10.9786 22.6269C11.6764 25.1071 12.9036 27.4067 14.5756 29.367C16.2476 31.3274 18.3247 32.902 20.6638 33.9823C23.0029 35.0627 25.5484 35.6231 28.1249 35.625C28.6443 35.625 29.1581 35.6006 29.6662 35.5575L29.6774 35.6287C26.6633 35.7085 23.7858 36.9027 21.6009 38.9806C19.416 41.0584 18.0789 43.8724 17.8481 46.8787H29.3756C30.0385 46.8768 30.6736 46.6123 31.142 46.1432C31.6104 45.6741 31.8739 45.0385 31.8749 44.3756V17.4994C31.873 16.8371 31.609 16.2025 31.1407 15.7342C30.6724 15.2659 30.0378 15.002 29.3756 15Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M27.5006 15H10.5337C10.1291 17.5446 10.2809 20.1466 10.9786 22.6269C11.6764 25.1071 12.9036 27.4067 14.5756 29.367C16.2476 31.3274 18.3247 32.902 20.6638 33.9823C23.0029 35.0627 25.5484 35.6231 28.1249 35.625C28.6443 35.625 29.1581 35.6006 29.6662 35.5575L29.6774 35.6287C26.6633 35.7085 23.7858 36.9027 21.6009 38.9806C19.416 41.0584 18.0789 43.8724 17.8481 46.8787H27.5006C28.1635 46.8768 28.7986 46.6123 29.267 46.1432C29.7354 45.6741 29.9989 45.0385 29.9999 44.3756V17.4994C29.998 16.8371 29.734 16.2025 29.2657 15.7342C28.7974 15.2659 28.1628 15.002 27.5006 15Z"
      fill="black"
    />
    <path
      d="M27.5006 15H2.49938C1.11901 15 0 16.119 0 17.4994V42.5006C0 43.881 1.11901 45 2.49938 45H27.5006C28.881 45 30 43.881 30 42.5006V17.4994C30 16.119 28.881 15 27.5006 15Z"
      fill="#038387"
    />
    <path
      d="M11.2755 29.8124C10.6188 29.3797 10.0697 28.8025 9.67049 28.1249C9.28212 27.4134 9.0881 26.6121 9.10799 25.8018C9.07497 24.7083 9.44462 23.6407 10.1467 22.8018C10.8855 21.9601 11.8407 21.337 12.9086 20.9999C14.1261 20.5992 15.4013 20.4015 16.683 20.4149C18.3686 20.353 20.0519 20.5892 21.6555 21.1124V24.6243C20.9585 24.2011 20.1992 23.8904 19.4055 23.7037C18.5465 23.4924 17.6651 23.386 16.7805 23.3868C15.8468 23.3504 14.9184 23.5435 14.0767 23.9493C13.7593 24.0867 13.4888 24.3136 13.2983 24.6024C13.1079 24.8911 13.0057 25.229 13.0042 25.5749C13.0006 26.003 13.1633 26.4157 13.458 26.7262C13.8089 27.0902 14.224 27.3864 14.6824 27.5999C15.1936 27.8499 15.9586 28.188 16.9774 28.6143C17.0901 28.6492 17.1998 28.6931 17.3055 28.7455C18.3094 29.1378 19.2779 29.6152 20.2005 30.1724C20.899 30.6031 21.4851 31.1938 21.9105 31.8955C22.3476 32.6893 22.5608 33.5869 22.5274 34.4924C22.5771 35.6176 22.233 36.7249 21.5542 37.6237C20.8771 38.4507 19.9713 39.0598 18.9499 39.3749C17.7504 39.7517 16.4983 39.934 15.2411 39.9149C14.1146 39.9201 12.9897 39.8272 11.8792 39.6374C10.9407 39.4883 10.0243 39.2232 9.15112 38.848V35.1355C9.98761 35.7317 10.9225 36.176 11.913 36.448C12.897 36.7546 13.92 36.9182 14.9505 36.9337C15.9055 36.9946 16.8589 36.7923 17.7067 36.3487C17.9977 36.1842 18.2385 35.9439 18.4036 35.6533C18.5687 35.3627 18.6519 35.0328 18.6442 34.6987C18.6456 34.4641 18.6004 34.2317 18.5113 34.0147C18.4221 33.7978 18.2908 33.6007 18.1249 33.4349C17.7043 33.0247 17.2183 32.6876 16.6867 32.4374C16.078 32.1299 15.1767 31.7268 13.983 31.228C13.0356 30.8475 12.1286 30.3733 11.2755 29.8124Z"
      fill="white"
    />
  </svg>
);
export const layer2Icon8 = (
    <svg role="img" aria-labelledby="layer2Icon8"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon8">Microsoft Project app icon</title>
    <path
      d="M39.375 22.5H7.5V6.1875C7.5 4.875 8.625 3.75 9.9375 3.75H36.75C38.0625 3.75 39.1875 4.875 39.1875 6.1875V22.5H39.375Z"
      fill="#33C481"
    />
    <path
      d="M48.75 39.375L34.125 43.875L10.125 39.375C8.625 39.375 7.5 38.25 7.5 36.75V20.625H46.125C47.625 20.625 48.75 21.75 48.75 23.25V39.375Z"
      fill="#21A366"
    />
    <path
      d="M20.625 39.375H57.5625C58.875 39.375 60 40.5 60 41.8125V54C60 55.3125 58.875 56.4375 57.5625 56.4375H23.0625C21.75 56.4375 20.625 55.3125 20.625 54V39.375Z"
      fill="#185C37"
    />
    <path
      opacity="0.1"
      d="M20.625 37.5V48.75H31.3125C32.625 48.75 33.75 47.625 33.75 46.3125V15.5625C33.75 14.25 32.625 13.125 31.3125 13.125H7.5V20.625V22.5V35.0625C7.5 36.375 8.625 37.5 9.9375 37.5H18.75H20.625Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M20.625 37.5V50.625H29.4375C30.75 50.625 31.875 49.5 31.875 48.1875V17.4375C31.875 16.125 30.75 15 29.4375 15H7.5V20.625V22.5V35.0625C7.5 36.375 8.625 37.5 9.9375 37.5H18.75H20.625Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M20.625 37.5V46.875H29.4375C30.75 46.875 31.875 45.75 31.875 44.4375V17.4375C31.875 16.125 30.75 15 29.4375 15H7.5V20.625V22.5V35.0625C7.5 36.375 8.625 37.5 9.9375 37.5H18.75H20.625Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M20.625 37.5V46.875H27.5625C28.875 46.875 30 45.75 30 44.4375V17.4375C30 16.125 28.875 15 27.5625 15H7.5V20.625V22.5V35.0625C7.5 36.375 8.625 37.5 9.9375 37.5H18.75H20.625Z"
      fill="black"
    />
    <path
      d="M27.5625 45H2.4375C1.125 45 0 43.875 0 42.5625V17.4375C0 16.125 1.125 15 2.4375 15H27.375C28.6875 15 29.8125 16.125 29.8125 17.4375V42.375C30 43.875 28.875 45 27.5625 45Z"
      fill="#107C41"
    />
    <path
      d="M15 20.625C17.25 20.625 18.9375 21.1875 20.25 22.125C21.375 23.0625 22.125 24.5625 22.125 26.4375C22.125 27.75 21.75 28.5 21.1875 29.4375C20.625 30.375 19.6875 31.125 18.75 31.6875C17.625 32.25 16.3125 32.4375 15 32.4375H11.25V39.375H7.5V20.625H15ZM11.25 29.4375H14.4375C15.75 29.4375 16.6875 29.25 17.25 28.6875C17.8125 28.125 18.1875 27.75 18.1875 26.8125C18.1875 24.75 17.0625 23.625 14.625 23.625H11.25V29.4375Z"
      fill="#F9F7F7"
    />
  </svg>
);
export const layer2Icon9 = (
    <svg role="img" aria-labelledby="layer2Icon9"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon9">Microsoft OneNote app icon</title>
    <path
      d="M57.4994 3H17.499C16.119 3 14.999 4.08555 14.999 5.4231V53.8851C14.999 55.2227 16.119 56.3082 17.499 56.3082H50.5719C55.7794 56.3082 59.9995 52.218 59.9995 47.1707V5.4231C59.9995 4.08555 58.8795 3 57.4994 3"
      fill="#CA64EA"
    />
    <path
      d="M45 41.7695V56.3081H57.5001C58.8801 56.3081 60.0001 55.2226 60.0001 53.885V41.7695H45Z"
      fill="#7719AA"
    />
    <path d="M45 41.7693H60.0001V27.2307H45V41.7693Z" fill="#9332BF" />
    <path d="M45 27.2307H60.0001V12.6921H45V27.2307Z" fill="#AE4BD5" />
    <path
      opacity="0.75"
      d="M34.5832 15.1155H15.0005V49.0389H34.0107C35.7807 49.0389 37.5007 47.3112 37.5007 45.758V17.9432C37.5007 16.3876 36.1882 15.1155 34.5832 15.1155"
      fill="black"
    />
    <path
      d="M32.0828 46.6153H2.91503C1.31251 46.6153 0 45.3432 0 43.7876V15.5197C0 13.964 1.31251 12.6919 2.91503 12.6919H32.0828C33.6878 12.6919 35.0004 13.964 35.0004 15.5197V43.7876C35.0004 45.3432 33.6878 46.6153 32.0828 46.6153"
      fill="#7719AA"
    />
    <path
      d="M9.49561 19.9609H13.7581L21.1082 32.4714C21.4907 33.036 21.7457 33.9883 21.9357 34.3299H21.9907C21.9082 33.855 21.8682 32.9972 21.8682 31.7566V19.9609H25.5033V39.3457H21.5132L13.8807 26.4524C13.5181 25.9072 13.2581 25.4614 13.1031 25.1197H13.0356C13.1081 25.6988 13.1456 26.6414 13.1456 27.9426V39.3457H9.49561V19.9609Z"
      fill="white"
    />
  </svg>
);
export const layer2Icon10 = (
    <svg role="img" aria-labelledby="layer2Icon10"
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title id="layer2Icon10">Microsoft Defender app icon</title>
    <path
      d="M45.7387 34.5228C46.7322 34.5228 47.5165 33.7384 47.5165 32.745C47.5165 31.9084 47.0982 31.5424 46.7845 31.3332C46.523 31.1764 46.157 31.0718 45.791 31.0718C45.425 31.0718 45.059 31.1764 44.7975 31.3332C44.4315 31.5424 44.0132 31.9607 44.0132 32.745C43.9609 33.7384 44.7452 34.5228 45.7387 34.5228Z"
      fill="#095391"
    />
    <path
      d="M30.1046 2L6 2.26144V7.75163L43.6471 30.6536C43.8039 30.4967 43.9608 30.3922 44.1699 30.2876C45.1634 29.7124 46.4706 29.7124 47.4118 30.2876C48.3006 30.8105 48.7712 31.6993 48.7712 32.7974C48.7712 34.4706 47.4118 35.8301 45.7386 35.8301C44.0654 35.8301 42.7059 34.4706 42.7059 32.7974C42.7059 32.4314 42.7582 32.0654 42.915 31.6993L6 9.21569V31.5425C6 47.6471 28.0131 57.1111 28.9542 57.4771L30.1569 58L31.3595 57.4771C31.7778 57.268 36.902 55.1242 42.183 51.1503L46.1569 47.7516L17.3464 29.451C16.8235 29.9739 16.0392 30.3399 15.2026 30.3399C13.5294 30.3399 12.1699 28.9804 12.1699 27.3072C12.1699 25.634 13.5294 24.2745 15.2026 24.2745C16.8758 24.2745 18.2353 25.634 18.2353 27.3072C18.2353 27.6732 18.183 28.0392 18.0261 28.4052L46.9935 46.915C51.0196 42.8366 54.2091 37.6601 54.2091 31.5425V2.26144L30.1046 2Z"
      fill="#095391"
    />
  </svg>
);
