import "./designDay.scss";

import { MSLogo, StudioLogo } from "../common/Icons";

import React, { useState, useEffect } from "react";
import cloud from "../../img/minified/design_day.png";
import cloudmobile from "../../img/minified/design_day_mobile.png";

export const DesignDay = () => {
       const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 767;
    const couldImage = isMobile ? cloudmobile : cloud;

  return (
    <>
          <div className="design-challenge" id="designday" role="region" aria-label="Design Day 2022">
        <div className="primary-background">
          <img src={couldImage} alt="design day" />
        </div>
        <div className="design-container">
          <div className="logo">
            <div className="mslogo">{MSLogo}</div>
            <div className="separator"></div>
            <div className="stlogo">{StudioLogo}</div>
          </div>
          <div className="design-heading">Design Day 2022</div>
          <div className="design-subtext">
            A memorable and insightful evening spent with Microsoft Design leadership and industry experts.
          </div>
                  <a className="design-day-link" href="https://www.instagram.com/p/Cf6VH2ygOCx/" target="_blank" rel="noopener noreferrer" aria-label="Design Day 2022 Event">Event Highlight Reel</a>
        </div>
      </div>
    </>
  );
};
