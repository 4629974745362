import "./studiovideos.scss";

import React from "react";
import bengulurecampus from "../../img/bengalure_campus.png";
import hydcampus from "../../img/hyd_campus.png";
import kptowers from "../../img/kp_towers.png";
import sovereignoffice from "../../img/sovereign_office.png";

export const StudioVideos: React.FC = () => {
  return (
    <>
          <div className="studioLocations videosContainer" role="region" aria-label="Virtual Tours of Microsoft IDC">
        <h2>Virtual Tours of Microsoft IDC</h2>
        <div className="campusVideos">
          <a
            href="https://www.youtube.com/watch?v=HxZatIzWDoU"
            target="_blank"
                      className="videoCard"
                      aria-label="Link KP towers campus Noida KP towers"
            >
            <div className="imagecontainer">
              <img
                src={kptowers}
                alt="Link KP towers campus Noida KP towers"
                className="videoImage"
              />
                      </div>
                      <div className="locationName">Noida, KP Towers</div>
          </a>
          <a
            href="https://www.youvisit.com/tour/microsoftin"
            target="_blank"
            className="videoCard"
            aria-label="Link Hyderabad campus"
          >
            <div className="imagecontainer">
              <img
                src={hydcampus}
                alt="Link Hyderabad campus"
                className="videoImage"
              />
            </div>
            <div className="locationName"> Hyderabad Campus</div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=0qgnAG-uQoY"
            target="_blank"
            className="videoCard"
            aria-label="Link Sovereign campus Noida, Sovereign Office"
          >
            <div className="imagecontainer">
              <img
                src={sovereignoffice}
                alt="Link Sovereign campus Noida, Sovereign Office"
                className="videoImage"
              />
            </div>
            <div className="locationName">Noida, Sovereign Office</div>
          </a>
          <a
            href="videos/Microsoft_Bengaluru_Office_1.mp4"
            target="_blank"
            className="videoCard"
            aria-label="Link Bengaluru campus"
          >
            <div className="imagecontainer">
              <img
                src={bengulurecampus}
                alt="Link Bengaluru campus"
                className="videoImage"
              />
            </div>
            <div className="locationName">Bengaluru Campus</div>
          </a>
        </div>
      </div>
    </>
  );
};
