import "./designchallenge.scss";

import { MSLogo, StudioLogo } from "../common/Icons";

import React, { useState, useEffect } from "react";
import cloud from "../../img/design_challenge.png";
import cloudmobile from "../../img/design_challenge_mobile.png";

export const DesignChallege = () => {
    
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 767;
    const couldImage = isMobile ? cloudmobile : cloud;    

  return (
    <>
        <div className="design-challenge" id="designChallenge" role="region" aria-label="Design Challenge 2022">
        <div className="primary-background">
          <img src={couldImage} alt="design challenge background" />
        </div>
        <div className="design-container">
          <div className="logo">
            <div className="mslogo">{MSLogo}</div>
            <div className="separator"></div>
            <div className="stlogo">{StudioLogo}</div>
          </div>
          <div className="design-heading">Design Challenge 2022</div>
          <div className="design-subtext">
           Winners of the most awaited design challenge of the year  have now been announced!
          </div>
          <a
            className="design-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://msdesignchallenge.in/"
            aria-label="Learn more about Design Challenge 2022"
          >
            Learn More
          </a>
        </div>
      </div>
    </>
  );
};
