import React from "react";
import happyFace from "../../img/happy-smile.svg";


export const Navigation: React.FC<{ hamburgerOpen:boolean,setHamburgerOpen: (isOpen:boolean) => void }> = (props) => {

    const navLinkHandler = () => {
        document.body.classList.remove('modal-open')
        props.setHamburgerOpen(false)
    }

    return (
        <>
            <nav className="headerRightContent" aria-label="Header">
             <a className="header-link skip-main" href="#main">Skip to main content</a>
              <a href="#designday" className="header-link" onClick={navLinkHandler }>
              Design Day &apos;22
            </a>
              <a href="#designChallenge" className="header-link" onClick={navLinkHandler}>
              Design Challenge &apos;22
            </a>
              <a href="#joinUs" className="header-link" onClick={navLinkHandler}>
              Join Us
                  </a>
              <a href="https://www.instagram.com/microsoft.studio91/" className="header-link"  onClick={navLinkHandler}>
                Instagram
            </a>
            </nav>
            <article className="msQuotes">
                <img src={happyFace} alt="Happy Face" />
                <p>Here to delight everyone, <br/> everyday, everytime! </p>
            </article>
        </>
      );
};
